import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select'
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import RequestHelper from '../../../../utils/Request.Utils';
import {
  setTourSessionAction,
} from '../../../../store/virtualTour/actions';
import * as Constants from '../../../../constants'
import DateTimePicker from 'react-datetime-picker';
import './GroupNameModal.scss';
import "./react-datetime.css";
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { roundTimeToInterval } from '../../../../utils/Common.Utils';

interface Props {
  open: boolean,
  onClose(): void,
  [additionProps: string]: any,
}

const DATE_FORMAT = 'DD/MM/YYYY';

const GroupNameModal = (props: Props) => {
  const { virtualTourState, userState } = useSelector((state: any) => ({
    virtualTourState: state.virtualTour,
    userState: state.user,
  }));
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState(virtualTourState.tourSession?.groupName || '');
  // const [allClients, setAllClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [inviteClientMessage, setInviteClientMessage] = useState('');
  const [inviteNormalMessage, setInviteNormalMessage] = useState('');
  const [inviteEmails, setInviteEmails] = useState('');
  const [showScheduleTime, setShowScheduleTime] = useState(false);
  const [isTourSecret, setIsTourSecret] = useState(false);
  const [scheduleTime, setScheduleTime] = useState<Moment>();
  const [settingsMessage, setSettingsMessage] = useState('');

  useEffect(() => {
    setGroupName(virtualTourState.tourSession?.groupName);
  }, [virtualTourState.tourSession?.groupName])

  useEffect(() => {
    if (virtualTourState.tourSession?.scheduleTime && moment(virtualTourState.tourSession?.scheduleTime).year() > 2000) {
      setScheduleTime(moment(virtualTourState.tourSession?.scheduleTime));
      setShowScheduleTime(true);
      setIsTourSecret(virtualTourState.tourSession?.isSecretTour);
    }
  }, [virtualTourState.tourSession])

  // const getAllClients = async () => {
  //   const res = await RequestHelper.get(`/tour-session/group/find-existing-clients`, {});
  //   if (res.data.data) {
  //     setAllClients(res.data.data.map(val => ({ label: val.name, value: val.email, ...val })));
  //   }
  // }

  // useEffect(() => {
  //   getAllClients();
  // }, []);

  // const url = `https://${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${window.location.pathname}`;
  const url = window.location.href;

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const copyLink = () => {
    copyToClipboard(url);
  }

  const canUpdateGroupName = userState.user.ID === virtualTourState.tourSession?.brokerId;

  const updateGroupName = async e => {
    if (!groupName || groupName === virtualTourState.tourSession?.groupName) return;
    const res = await RequestHelper.post(`/tour-session/update-group/${virtualTourState.tourSession?.ID}`, {
      groupName,
    });
    if (res.data.success) {
      dispatch(setTourSessionAction({
        ...virtualTourState.tourSession,
        groupName,
      }));
    }
  }

  const inviteClientEmails = async () => {
    const emails = selectedClients.map(val => val.value);
    setInviteClientMessage('Sending Invite...');
    const res = await RequestHelper.post(`/tour-session/group/send-invite`, {
      tourId: virtualTourState.tourSession?.ID,
      emails,
      tourName: virtualTourState.tourSession.tourName,
    });
    if (res.data.success) {
      setInviteClientMessage('Done!');
      setTimeout(() => {
        setInviteClientMessage('');
        setSelectedClients([]);
      }, 1000);
    } else {
      setInviteClientMessage('Error, please try again!');
      setTimeout(() => {
        setInviteClientMessage('');
      }, 1000);
    }
  }

  const inviteNormalEmails = async (data = { noEmails: false }) => {
    const emails = inviteEmails.split(',').map(val => val.trim());
    !data.noEmails && setInviteNormalMessage('Sending Invite...');
    RequestHelper.post(`/tour-session/update-group/${virtualTourState.tourSession?.ID}`, {
      scheduleTime: showScheduleTime && !!scheduleTime ? scheduleTime.format() : undefined,
      isSecretTour: showScheduleTime ? isTourSecret : undefined,
    });
    const res = await RequestHelper.postNextServer(`/send-invite-tour-email`, {
      tourId: virtualTourState.tourSession?.ID,
      emails: data.noEmails ? [] : emails,
      tourName: groupName || virtualTourState.tourSession?.groupName || virtualTourState.tourSession?.tourName,
      scheduleTime: showScheduleTime && !!scheduleTime ? scheduleTime.format() : undefined,
      isSecretTour: showScheduleTime ? isTourSecret : undefined,
    });
    if (res.data.success) {
      if (data.noEmails) {
        setSettingsMessage('Done!');
        setTimeout(() => {
          setSettingsMessage('');
        }, 1000);
      } else {
        setInviteNormalMessage('Done!');
        setTimeout(() => {
          setInviteNormalMessage('');
          setInviteEmails('');
        }, 1000);
      }
      
    } else {
      setInviteNormalMessage('Error, please try again!');
      setTimeout(() => {
        setInviteNormalMessage('');
      }, 1000);
    }
  }

  const onChangeTime = (time: Moment) => {
    if (moment(time).isValid()) {
      setScheduleTime(roundTimeToInterval(time, 5, time.isAfter(scheduleTime)))
    }
  }

  return (
    <CustomModal
      show={props.open}
      onHide={props.onClose}
      centered
      className="reset-password-modal"
    >
      <Modal.Body className="group-name-modal">
        <div>
          <input
            readOnly={!canUpdateGroupName}
            value={groupName} onChange={e => setGroupName(e.target.value)}
            placeholder="Enter Group Name"
            className="dark"
            onBlur={updateGroupName}
          />

          <div className="d-flex align-items-center justify-content-between mt-4 mb-1">
            <h4>Get Link</h4>
            <button className="btn-copy-link" onClick={copyLink}>Copy Link</button>
          </div>
          <p className="link">{url}</p>

          <div className="d-flex align-items-center justify-content-between mt-4 mb-1">
            <h4>Email Link</h4>
            <button disabled={!!inviteNormalMessage} className="btn-copy-link" onClick={() => inviteNormalEmails({ noEmails: false })}>{!!inviteNormalMessage ? inviteNormalMessage : 'Send'}</button>
          </div>
          <input
            className={`invite-emails for-${Constants.SITE} dark`}
            value={inviteEmails}
            onChange={e => setInviteEmails(e.target.value)}
            placeholder="Enter emails, separated by comma"
          />

          <div className="mt-4 mb-1">
            <label>
              <input
                type="checkbox" style={{ width: 'auto' }}
                checked={showScheduleTime} onChange={e => setShowScheduleTime(e.target.checked)}
              />
              <span className="ml-2">Schedule Time</span>
            </label>
            {showScheduleTime && (
              <div className="mt-2 mb-1" style={{ width: '100%' }}>
                <div style={{ width: 300 }}>
                  <Datetime
                    value={scheduleTime}
                    dateFormat={DATE_FORMAT}
                    onChange={onChangeTime}
                    inputProps={{
                      placeholder: 'Tap to choose',
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="mt-3 mb-1">
                  <label>
                    <input
                      type="checkbox" style={{ width: 'auto' }}
                      checked={isTourSecret} onChange={e => setIsTourSecret(e.target.checked)}
                    />
                    <span className="ml-2">Hide tour link until the schedule time</span>
                  </label>
                </div>
                
                
              </div>
            )}
            <div className="mt-2 mb-1 d-flex justify-content-between">
              <p style={{ fontSize: '0.8em', width: '50%' }}>Note: please make sure you setup the schedule time before sending the invite emails</p>
              <button className="btn-copy-link" onClick={() => inviteNormalEmails({ noEmails: true })}>{settingsMessage || 'Save settings'}</button>
            </div>
          </div>

        </div>
      </Modal.Body>
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .form-group {
    margin-bottom: 0.8rem;

    label {
      margin: 0;
      color: white;
      opacity: 0.8;
      font-size: 0.8rem;
    }

    input {
      background: transparent;
      border: none;
      outline: none !important;
      box-shadow: none;
      border-bottom: 1px solid white;
      border-radius: 0;
      padding: 0;
      color: white;
      height: 1.5rem;
    }
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }
  }

  Button {
    background: white;
    width: 100%;
    color: orange;
    margin-top: 1rem;
    border: 1rem;
    height: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .signin-btn {
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 991px){
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`

export default GroupNameModal;
